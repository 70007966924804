<template>
	<v-stepper-items>
		<v-stepper-content class="py-0 px-0" step="2">
			<TributeForm
				v-if="store.s1FormData.tribute"
				@nextStep="nextStep"
				@previousStep="previousStep"
			/>

			<DonorForm v-else @nextStep="nextStep" @previousStep="previousStep" />
		</v-stepper-content>
	</v-stepper-items>
</template>

<script>
import DonorForm from "../Forms/DonorForm.vue";
import TributeForm from "../Forms/TributeForm.vue";

import { useFormDataStore } from "../../store/formData.js";

export default {
	name: "S2Form",
	data() {
		return {};
	},
	setup() {
		const store = useFormDataStore();

		return {
			store,
		};
	},
	components: {
		DonorForm,
		TributeForm,
	},
	methods: {
		nextStep() {
			this.$emit("nextStepFromTwo");
		},
		previousStep() {
			this.$emit("previousStepFromTwo");
		},
	},
};
</script>

<style lang="scss" scoped></style>
