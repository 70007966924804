import { defineStore } from "pinia";

export const useFormDataStore = defineStore({
	id: "formData",
	state: () => {
		return {
			recaptchaSiteKey: "6LdeTgUhAAAAADPqAshr1dHh7olck_uI5jaiPU0n",
			recaptchaObject: null,
			campaignId: null,
			restrictedAccess: null,
			testMode: null,
			formParams: null,
			completedFn: null,
			s1FormData: {},
			s2FormTributeData: {},
			s2FormData: {},
			s3FormData: {},
			formSubmissionData: {},
			countryOptions: {
				Canada: "CA",
				"United States": "US",
				Andorra: "AD",
				"United Arab Emirates": "AE",
				Afghanistan: "AF",
				"Antigua and Barbuda": "AG",
				Anguilla: "AI",
				Albania: "AL",
				Armenia: "AM",
				Angola: "AO",
				Antarctica: "AQ",
				Argentina: "AR",
				Austria: "AT",
				Australia: "AU",
				Aruba: "AW",
				"Aland Islands": "AX",
				Azerbaijan: "AZ",
				"Bosnia and Herzegovina": "BA",
				Barbados: "BB",
				Bangladesh: "BD",
				Belgium: "BE",
				"Burkina Faso": "BF",
				Bulgaria: "BG",
				Bahrain: "BH",
				Burundi: "BI",
				Benin: "BJ",
				"Saint Barthélemy": "BL",
				Bermuda: "BM",
				"Brunei Darussalam": "BN",
				"Bolivia, Plurinational State of": "BO",
				"Bonaire, Sint Eustatius and Saba": "BQ",
				Brazil: "BR",
				Bahamas: "BS",
				Bhutan: "BT",
				"Bouvet Island": "BV",
				Botswana: "BW",
				Belarus: "BY",
				Belize: "BZ",
				"Cocos (Keeling) Islands": "CC",
				"Congo, the Democratic Republic of the": "CD",
				"Central African Republic": "CF",
				Congo: "CG",
				Switzerland: "CH",
				"Cote d’Ivoire": "CI",
				"Cook Islands": "CK",
				Chile: "CL",
				Cameroon: "CM",
				China: "CN",
				Colombia: "CO",
				"Costa Rica": "CR",
				Cuba: "CU",
				"Cape Verde": "CV",
				Curaçao: "CW",
				"Christmas Island": "CX",
				Cyprus: "CY",
				"Czech Republic": "CZ",
				Germany: "DE",
				Djibouti: "DJ",
				Denmark: "DK",
				Dominica: "DM",
				"Dominican Republic": "DO",
				Algeria: "DZ",
				Ecuador: "EC",
				Estonia: "EE",
				Egypt: "EG",
				"Western Sahara": "EH",
				Eritrea: "ER",
				Spain: "ES",
				Ethiopia: "ET",
				Finland: "FI",
				Fiji: "FJ",
				"Falkland Islands (Malvinas)": "FK",
				"Faroe Islands": "FO",
				France: "FR",
				Gabon: "GA",
				"United Kingdom": "GB",
				Grenada: "GD",
				Georgia: "GE",
				"French Guiana": "GF",
				Guernsey: "GG",
				Ghana: "GH",
				Gibraltar: "GI",
				Greenland: "GL",
				Gambia: "GM",
				Guinea: "GN",
				Guadeloupe: "GP",
				"Equatorial Guinea": "GQ",
				Greece: "GR",
				"South Georgia and the South Sandwich Islands": "GS",
				Guatemala: "GT",
				"Guinea-Bissau": "GW",
				Guyana: "GY",
				"Heard Island and McDonald Islands": "HM",
				Honduras: "HN",
				Croatia: "HR",
				Haiti: "HT",
				Hungary: "HU",
				Indonesia: "ID",
				Ireland: "IE",
				Israel: "IL",
				"Isle of Man": "IM",
				India: "IN",
				"British Indian Ocean Territory": "IO",
				Iraq: "IQ",
				"Iran, Islamic Republic of": "IR",
				Iceland: "IS",
				Italy: "IT",
				Jersey: "JE",
				Jamaica: "JM",
				Jordan: "JO",
				Japan: "JP",
				Kenya: "KE",
				Kyrgyzstan: "KG",
				Cambodia: "KH",
				Kiribati: "KI",
				Comoros: "KM",
				"Saint Kitts and Nevis": "KN",
				"Korea, Democratic People’s Republic of": "KP",
				"Korea, Republic of": "KR",
				Kuwait: "KW",
				"Cayman Islands": "KY",
				Kazakhstan: "KZ",
				"Lao People’s Democratic Republic": "LA",
				Lebanon: "LB",
				"Saint Lucia": "LC",
				Liechtenstein: "LI",
				"Sri Lanka": "LK",
				Liberia: "LR",
				Lesotho: "LS",
				Lithuania: "LT",
				Luxembourg: "LU",
				Latvia: "LV",
				Libya: "LY",
				Morocco: "MA",
				Monaco: "MC",
				"Moldova, Republic of": "MD",
				Montenegro: "ME",
				"Saint Martin (French part)": "MF",
				Madagascar: "MG",
				"Macedonia, the former Yugoslav Republic of": "MK",
				Mali: "ML",
				Myanmar: "MM",
				Mongolia: "MN",
				Macao: "MO",
				Martinique: "MQ",
				Mauritania: "MR",
				Montserrat: "MS",
				Malta: "MT",
				Mauritius: "MU",
				Maldives: "MV",
				Malawi: "MW",
				Mexico: "MX",
				Malaysia: "MY",
				Mozambique: "MZ",
				Namibia: "NA",
				"New Caledonia": "NC",
				Niger: "NE",
				"Norfolk Island": "NF",
				Nigeria: "NG",
				Nicaragua: "NI",
				Netherlands: "NL",
				Norway: "NO",
				Nepal: "NP",
				Nauru: "NR",
				Niue: "NU",
				"New Zealand": "NZ",
				Oman: "OM",
				Panama: "PA",
				Peru: "PE",
				"French Polynesia": "PF",
				"Papua New Guinea": "PG",
				Philippines: "PH",
				Pakistan: "PK",
				Poland: "PL",
				"Saint Pierre and Miquelon": "PM",
				Pitcairn: "PN",
				Palestine: "PS",
				Portugal: "PT",
				Paraguay: "PY",
				Qatar: "QA",
				Reunion: "RE",
				Romania: "RO",
				Serbia: "RS",
				"Russian Federation": "RU",
				Rwanda: "RW",
				"Saudi Arabia": "SA",
				"Solomon Islands": "SB",
				Seychelles: "SC",
				Sudan: "SD",
				Sweden: "SE",
				Singapore: "SG",
				"Saint Helena, Ascension and Tristan da Cunha": "SH",
				Slovenia: "SI",
				"Svalbard and Jan Mayen": "SJ",
				Slovakia: "SK",
				"Sierra Leone": "SL",
				"San Marino": "SM",
				Senegal: "SN",
				Somalia: "SO",
				Suriname: "SR",
				"South Sudan": "SS",
				"Sao Tome and Principe": "ST",
				"El Salvador": "SV",
				"Sint Maarten (Dutch part)": "SX",
				"Syrian Arab Republic": "SY",
				Swaziland: "SZ",
				"Turks and Caicos Islands": "TC",
				Chad: "TD",
				"French Southern Territories": "TF",
				Togo: "TG",
				Thailand: "TH",
				Tajikistan: "TJ",
				Tokelau: "TK",
				"Timor-Leste": "TL",
				Turkmenistan: "TM",
				Tunisia: "TN",
				Tonga: "TO",
				Turkey: "TR",
				"Trinidad and Tobago": "TT",
				Tuvalu: "TV",
				Taiwan: "TW",
				"Tanzania, United Republic of": "TZ",
				Ukraine: "UA",
				Uganda: "UG",
				Uruguay: "UY",
				Uzbekistan: "UZ",
				"Holy See (Vatican City State)": "VA",
				"Saint Vincent and the Grenadines": "VC",
				"Venezuela, Bolivarian Republic of": "VE",
				"Virgin Islands, British": "VG",
				Vietnam: "VN",
				Vanuatu: "VU",
				"Wallis and Futuna": "WF",
				Samoa: "WS",
				Yemen: "YE",
				Mayotte: "YT",
				"South Africa": "ZA",
				Zambia: "ZM",
				Zimbabwe: "ZW",
			},
			canadaProvinceOptions: {
				Alberta: "AB",
				"British Columbia": "BC",
				Manitoba: "MB",
				"New Brunswick": "NB",
				"Newfoundland and Labrador": "NL",
				"Northwest Territories": "NT",
				"Nova Scotia": "NS",
				Nunavut: "NU",
				Ontario: "ON",
				"Prince Edward Island": "PE",
				Quebec: "QC",
				Saskatchewan: "SK",
				"Yukon Territory": "YT",
			},
			unitedStatesStateOptions: {
				Alabama: "AL",
				Alaska: "AK",
				"American Samoa": "AS",
				Arizona: "AZ",
				Arkansas: "AR",
				California: "CA",
				Colorado: "CO",
				Connecticut: "CT",
				Delaware: "DE",
				"District Of Columbia": "DC",
				Florida: "FL",
				Georgia: "GA",
				Guam: "GU",
				Hawaii: "HI",
				Idaho: "ID",
				Illinois: "IL",
				Indiana: "IN",
				Iowa: "IA",
				Kansas: "KS",
				Kentucky: "KY",
				Louisiana: "LA",
				Maine: "ME",
				Maryland: "MD",
				Massachusetts: "MA",
				Michigan: "MI",
				Minnesota: "MN",
				Mississippi: "MS",
				Missouri: "MO",
				Montana: "MT",
				Nebraska: "NE",
				Nevada: "NV",
				"New Hampshire": "NH",
				"New Jersey": "NJ",
				"New Mexico": "NM",
				"New York": "NY",
				"North Carolina": "NC",
				"North Dakota": "ND",
				"Northern Mariana Islands": "MP",
				Ohio: "OH",
				Oklahoma: "OK",
				Oregon: "OR",
				Pennsylvania: "PA",
				"Puerto Rico": "PR",
				"Rhode Island": "RI",
				"South Carolina": "SC",
				"South Dakota": "SD",
				Tennessee: "TN",
				Texas: "TX",
				Utah: "UT",
				Vermont: "VT",
				"Virgin Islands": "VI",
				Virginia: "VA",
				Washington: "WA",
				"West Virginia": "WV",
				Wisconsin: "WI",
				Wyoming: "WY",
			},
		};
	},
	actions: {
		setRecaptchaObject(object) {
			this.recaptchaObject = object;
		},
		setCampaignId(id) {
			this.campaignId = id;
		},
		setRestrictedAccess(state) {
			this.restrictedAccess = state;
		},
		setTestMode(state) {
			this.testMode = state;
		},
		setFormParams(paramData) {
			this.formParams = paramData;
		},
		setS1FormData(formData) {
			this.s1FormData = formData;
		},
		setS2FormData(formData) {
			this.s2FormData = formData;
		},
		setS2FormTributeData(formData) {
			this.s2FormTributeData = formData;
		},
		setS3FormData(formData) {
			this.s3FormData = formData;
		},
		setFormSubmissionData(formData) {
			this.formSubmissionData = formData;
		},
		setCompletedFn(fn) {
			this.completedFn = fn;
		},
	},
	getters: {},
});
