<template>
	<v-container class="pt-0">
		<v-row justify="center">
			<div style="width: 380px">
				<v-col cols="12" class="px-0">
					<v-row no-gutters class="mb-3">
						<h2 v-if="store.s1FormData.tribute">Step 4: Payment Information</h2>

						<h2 v-else>Step 3: Payment Information</h2>
					</v-row>

					<v-alert
						v-show="store.formSubmissionData.errorMessage != null"
						close-text="Close Alert"
						class="alert-text my-3"
						color="error"
						dark
						dismissible
					>
						{{ store.formSubmissionData.errorMessage }}
					</v-alert>

					<v-form ref="stepThreeForm">
						<v-text-field
							label="Cardholder Name *"
							id="Cardholder Name"
							class="error-field"
							v-model.trim="cardName"
							:rules="rules.cardName"
						></v-text-field>

						<v-text-field
							label="Card Number *"
							id="Card Number"
							class="error-field"
							v-model="cardNumber"
							v-mask="
								cardType == 'AMEX' ? '#### ###### #####' : '#### #### #### ####'
							"
							:maxlength="cardType == 'AMEX' ? 19 : 20"
							:error="cardNumberError"
							:error-messages="cardNumberErrorMessage"
							:rules="rules.cardNumber"
						>
							<template v-slot:append>
								<v-icon
									v-show="cardType == 'Visa' && cardNumber.length > 0"
									class="mr-5"
								>
									$vuetify.icons.cardVisa
								</v-icon>

								<v-icon
									v-show="cardType == 'Mastercard' && cardNumber.length > 0"
									class="mr-5"
								>
									$vuetify.icons.cardMastercard
								</v-icon>

								<v-icon
									v-show="cardType == 'AMEX' && cardNumber.length > 0"
									class="mr-5"
								>
									$vuetify.icons.cardAmericanExpress
								</v-icon>

								<v-icon
									v-show="cardType == 'Discover' && cardNumber.length > 0"
									class="mr-5"
								>
									$vuetify.icons.cardDiscover
								</v-icon>
							</template>
						</v-text-field>

						<v-row class="my-0 py-0">
							<v-col class="py-0" cols="12" xs="12" sm="8">
								<v-text-field
									label="Expiry Date (MM/YY) *"
									id="Expiry Date"
									class="error-field pb-0"
									v-model="cardExpiry"
									v-mask="'##/##'"
									maxlength="5"
									:rules="rules.cardExpiry"
								></v-text-field>
							</v-col>

							<v-col class="py-0" cols="12" xs="12" sm="4">
								<v-text-field
									label="CVV *"
									id="CVV"
									class="error-field"
									v-model="cardCVV"
									v-mask="'####'"
									maxlength="4"
									:rules="rules.cardCVV"
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>

					<v-row class="mt-3">
						<v-col sm="6" align="center">
							<v-btn class="btn-text" large @click="previousStep()">Back</v-btn>
						</v-col>
						<v-col sm="6" align="center">
							<v-btn class="btn-text" color="primary" large @click="nextStep()"
								>Next</v-btn
							>
						</v-col>
					</v-row>
				</v-col>
			</div>
		</v-row>
	</v-container>
</template>

<script>
import { useFormDataStore } from "../../store/formData.js";

export default {
	name: "CreditCardForm",
	data() {
		return {
			cardName: "",
			cardNumber: "",
			cardExpiry: "",
			cardCVV: "",
			cardType: "",
			cardNumberError: false,
			cardNumberErrorMessage: "",
			rules: {
				cardName: [],
				cardNumber: [],
				cardExpiry: [],
				cardCVV: [],
			},
		};
	},
	setup() {
		const store = useFormDataStore();

		return {
			store,
		};
	},
	watch: {
		cardNumber(input) {
			if (input != "") {
				if (input.substring(0, 1) == "4") {
					this.cardType = "Visa";
				} else if (input.substring(0, 1) == "5") {
					this.cardType = "Mastercard";
				} else if (input.substring(0, 1) == "6") {
					this.cardType = "Discover";
				} else if (
					input.substring(0, 2) == "34" ||
					input.substring(0, 2) == "37"
				) {
					this.cardType = "AMEX";
				} else {
					this.cardType = "";
				}
			}

			this.cardNumberError = false;
			this.cardNumberErrorMessage = "";
		},
		cardType() {
			if (this.rules.cardNumber.length > 0) {
				this.rules.cardNumber =
					this.cardType == "AMEX"
						? [
								(v) => !!v || "Required",
								(v) => (v || "").length >= 17 || "Invalid card number",
						  ]
						: [
								(v) => !!v || "Required",
								(v) => (v || "").length >= 19 || "Invalid card number",
						  ];
			}
		},
	},
	methods: {
		setFieldValidationRules() {
			this.rules.cardName = [(v) => !!v.trim() || "Required"];
			this.rules.cardNumber =
				this.cardType == "AMEX"
					? [
							(v) => !!v || "Required",
							(v) => (v || "").length >= 17 || "Invalid card number",
					  ]
					: [
							(v) => !!v || "Required",
							(v) => (v || "").length >= 19 || "Invalid card number",
					  ];
			this.rules.cardExpiry = [
				(v) => !!v || "Required",
				(v) => (v || "").length >= 5 || "Invalid expiry date",
			];
			this.rules.cardCVV = [
				(v) => !!v || "Required",
				(v) => (v || "").length >= 3 || "Invalid CVV",
			];
		},
		async nextStep() {
			// Wait for field validation rules to be set before validating form
			await this.setFieldValidationRules();

			// console.log({
			// 	cardHolder: this.cardName.trim(),
			// 	cardNumber: this.cardNumber,
			// 	cardExpiry: this.cardExpiry,
			// 	cardMonth: this.cardExpiry.split("/")[0],
			// 	cardYear: "20" + this.cardExpiry.split("/")[1],
			// 	cardCVV: this.cardCVV,
			// });

			if (this.$refs.stepThreeForm.validate()) {
				// Check if card number entered is either Visa, Mastercard AMEX or Discover
				if (this.cardType == "") {
					this.cardNumberError = true;
					this.cardNumberErrorMessage = "Invalid card number";

					this.rules.cardNumber = [];

					return;
				}

				this.store.setS3FormData({
					cardHolder: this.cardName.trim(),
					cardNumber: this.cardNumber,
					cardExpiry: this.cardExpiry,
					cardMonth: this.cardExpiry.split("/")[0],
					cardYear: "20" + this.cardExpiry.split("/")[1],
					cardCVV: this.cardCVV,
				});

				this.$emit("nextStep");
			} else {
				// Scroll to first error
				this.$nextTick(() => {
					const el = this.$el.querySelector(
						".v-messages.error--text:first-of-type"
					);
					this.$vuetify.goTo(el, { offset: 125 });

					return;
				});
			}
		},
		previousStep() {
			this.$emit("previousStep");
		},
	},
};
</script>

<style lang="scss" scoped>
.alert-text {
	color: white !important;
	font-weight: bold;
}

// Override RR website styling for textfields
div ::v-deep .v-text-field input {
	border: none !important;
	background: none !important;
	font-size: 16px !important;
}
</style>
