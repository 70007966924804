<template>
	<v-stepper-items>
		<v-stepper-content class="py-0 px-0" step="1">
			<v-container>
				<v-row justify="center">
					<div style="width: 400px">
						<v-col cols="12" class="pt-0 px-0">
							<h2 class="mb-3">Step 1: Amount Details</h2>

							<v-form ref="stepOneForm">
								<v-select
									label="Select Frequency: *"
									class="mb-3 custom-label-color"
									v-model="paymentFrequency"
									:items="paymentFrequencyOptions"
									hide-details
								></v-select>

								<div v-show="this.paymentFrequency !== 'One Time'">
									<v-menu
										v-model="datePickerMenu"
										:close-on-content-click="false"
										:nudge-right="20"
										transition="scale-transition"
										offset-y
										min-width="auto"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												label="Select Donation Date *"
												class="mb-3 textfield-rr-override"
												:value="formattedPaymentDate"
												readonly
												v-bind="attrs"
												v-on="on"
												hide-details
											></v-text-field>
										</template>
										<v-date-picker
											v-model="paymentStartDate"
											header-color="primary"
											color="primary"
											:min="currentDate"
											@input="datePickerMenu = false"
											width="250"
										></v-date-picker>
									</v-menu>
								</div>

								<v-subheader class="pa-0 subheader-text"
									>Select an amount: *</v-subheader
								>
								<v-radio-group
									v-model="selectedAmountRadioButton"
									class="ma-0 pa-0 justify-center error-field"
									:rules="rules.empty"
									:hide-details="donationSelectionError"
									:error="!donationSelectionError"
									:error-messages="donationSelectionErrorMessage"
									mandatory
								>
									<v-row class="mb-5" no-gutters>
										<v-col cols="5">
											<v-radio
												:key="donationAmounts[0]"
												:label="`$${donationAmounts[0].toFixed(2)}`"
												:value="donationAmounts[0]"
											></v-radio>
										</v-col>

										<v-col cols="5" class="pl-5">
											<v-radio
												:key="donationAmounts[1]"
												:label="`$${donationAmounts[1].toFixed(2)}`"
												:value="donationAmounts[1]"
											></v-radio>
										</v-col>
									</v-row>

									<v-row no-gutters class="mb-5">
										<v-col cols="5" overflow>
											<v-radio
												:key="donationAmounts[2]"
												:label="`$${donationAmounts[2].toFixed(2)}`"
												:value="donationAmounts[2]"
											></v-radio>
										</v-col>

										<v-col cols="5" class="pl-5" overflow>
											<v-radio
												:key="donationAmounts[3]"
												:label="`$${donationAmounts[3].toFixed(2)}`"
												:value="donationAmounts[3]"
											></v-radio>
										</v-col>
									</v-row>

									<v-row no-gutters>
										<v-col class="mb-0">
											<v-input
												v-model="selectedAmountRadioButton"
												class="ma-0"
												hide-details
												@click="selectOtherRadioButton()"
											>
												<v-radio
													label="Other"
													value="Other"
													class="mb-0 mr-2"
													hide-details
												></v-radio>

												<div style="width: 120px">
													<v-text-field
														prefix="$"
														maxlength="8"
														v-model="otherAmount"
														class="ma-0"
														:error="!donationSelectionError"
														outlined
														dense
														hide-details
														@blur="maskCurrency(otherAmount)"
													></v-text-field>
												</div>
											</v-input>
										</v-col>
									</v-row>
								</v-radio-group>

								<v-row no-gutters>
									<v-checkbox
										class="mb-3"
										v-model="transactionFeeCheckbox"
										hide-details
									>
										<template v-slot:label style="height: 0px">
											<div>
												I would like to cover the processing fee of 2.5%
											</div>
										</template>
									</v-checkbox>
								</v-row>

								<v-row v-show="transactionFeeCheckbox" class="mx-0">
									<span>
										<p>
											Transaction Fee: ${{
												(donationAmount * 0.025).toFixed(2)
											}}
										</p>
									</span>
								</v-row>

								<v-row class="mx-0 mt-3">
									<span>
										<h3>{{ donationAmountLabel() }}</h3>
										<h3 v-if="matchMultiplier" class="match-label">{{ ourMatchLabel() }}</h3>
										<h3 v-if="matchMultiplier" class="match-label">{{ totalImpactLabel() }}</h3>
									</span>
								</v-row>

								<!-- UNCOMMENT FOR TRIBUTE FORM CHECKBOX -->
								<!-- <div v-if="store.formParams">
									<v-row v-if="!store.formParams.hideTributeStep" no-gutters>
										<v-checkbox
											class="mt-5 mb-2"
											v-model="tributeCheckbox"
											hide-details
										>
											<template v-slot:label style="height: 0px">
												<div>
													This donation is in honour or in memory of someone
												</div>
											</template>
										</v-checkbox>
									</v-row>
								</div> -->

								<v-row class="mx-0 mt-5" align="center" justify="center">
									<v-btn
										class="btn-text"
										color="primary"
										large
										@click="nextStep()"
									>
										Next
									</v-btn>
								</v-row>
							</v-form>
						</v-col>
					</div>
				</v-row>
			</v-container>
		</v-stepper-content>
	</v-stepper-items>
</template>

<script>
import moment from "moment";
import { useFormDataStore } from "../../store/formData.js";

export default {
	name: "S1Form",
	data() {
		return {
			currency: "CAD",
			selectedAmountRadioButton: "",
			otherAmount: "",
			donationAmount: 0,
			donationTotal: 0,
			donationAmounts: [0, 0, 0, 0],
			donationAmountOptions: {
				"One Time": [],
				Monthly: [],
				Quarterly: [],
				Yearly: [],
			},
			donationSelectionError: true,
			donationSelectionErrorMessage: null,
			matchMultiplier: 0,
			paymentFrequency: "",
			paymentFrequencyOptions: [],
			datePickerMenu: false,
			paymentStartDate: new Date(
				Date.now() - new Date().getTimezoneOffset() * 60000
			)
				.toISOString()
				.substr(0, 10),
			currentDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
				.toISOString()
				.substr(0, 10),
			transactionFeeCheckbox: false,
			tributeCheckbox: false,
			rules: {
				empty: [(v) => !!v || "Required"],
			},
			info: "",
		};
	},
	setup() {
		const store = useFormDataStore();

		return {
			store,
		};
	},
	props: {
		overrideDonationAmounts: {
			type: Object,
		},
		defaultDonationOption: {
			type: Number,
			default: 0,
		},
		loadDonationParams: {
			type: Object,
		},
	},
	watch: {
		loadDonationParams() {
			let frequencies = Object.keys(this.loadDonationParams.suggestedAmounts);
			let frequencyParams = Object.values(
				this.loadDonationParams.suggestedAmounts
			);

			this.matchMultiplier = this.loadDonationParams.matchMultiplier || 0

			if (frequencies.length > 0) {
				this.paymentFrequency = frequencies[0];
				this.paymentFrequencyOptions = frequencies;

				frequencies.forEach((frequency, index) => {
					if (frequencyParams[index].amounts.length == 4) {
						if (index == 0) {
							this.donationAmounts = frequencyParams[index].amounts;

							// Set default donation amount selected
							if (
								frequencyParams[index].amounts.includes(
									frequencyParams[index].default
								)
							) {
								this.selectedAmountRadioButton = frequencyParams[index].default;
							} else {
								this.selectedAmountRadioButton =
									frequencyParams[index].amounts[0];
							}
						}

						// Set donation amounts for each payment frequency
						this.donationAmountOptions[frequency] =
							frequencyParams[index].amounts;
					}
				});
			}
		},
		selectedAmountRadioButton(amountRadioButton) {
			// Set donation amount based on current radio button selection
			if (amountRadioButton == "Other") {
				this.donationAmount =
					this.otherAmount != "" ? parseFloat(this.otherAmount) : 0;
			} else {
				// Clear minimum donation amount error if currently active and clear 'Other' amount field
				this.donationSelectionError = true;
				this.donationSelectionErrorMessage = null;
				this.otherAmount = "";

				this.donationAmount = amountRadioButton;
			}

			// Calculate donation amount total
			if (this.donationAmount >= 0) {
				this.calculateDonation();
			}
		},
		transactionFeeCheckbox(checkboxState) {
			this.transactionFeeCheckbox = checkboxState;

			this.calculateDonation();
		},
		otherAmount(amount) {
			if (
				!isNaN(parseFloat(amount)) &&
				this.selectedAmountRadioButton == "Other" &&
				parseFloat(amount) > 0
			) {
				this.donationAmount = this.donationTotal = parseFloat(amount);
			}

			this.calculateDonation();

			// Clear minimum donation amount error if currently active
			if ((amount >= 5) & !this.donationSelectionError) {
				this.donationSelectionError = true;
				this.donationSelectionErrorMessage = null;
			}
		},
		paymentFrequency(selectedFrequency) {
			if (selectedFrequency == "One Time") {
				this.donationAmounts = this.donationAmountOptions["One Time"];
			} else if (selectedFrequency == "Monthly") {
				this.donationAmounts = this.donationAmountOptions.Monthly;
			} else if (selectedFrequency == "Quarterly") {
				this.donationAmounts = this.donationAmountOptions.Quarterly;
			} else if (selectedFrequency == "Yearly") {
				this.donationAmounts = this.donationAmountOptions.Yearly;
			}

			// Set donation amount to minimum for each payment frequency
			this.$nextTick(() => {
				let frequencyParams = this.loadDonationParams.suggestedAmounts;

				if (Object.keys(frequencyParams).length > 0) {
					if (
						frequencyParams[selectedFrequency].amounts.includes(
							frequencyParams[selectedFrequency].default
						)
					) {
						this.selectedAmountRadioButton =
							frequencyParams[selectedFrequency].default;
					} else {
						this.selectedAmountRadioButton =
							frequencyParams[selectedFrequency].amounts[0];
					}
				} else {
					this.selectedAmountRadioButton = this.donationAmounts[0];
				}
			});
		},
	},
	computed: {
		formattedPaymentDate() {
			return this.paymentStartDate
				? moment(this.paymentStartDate).format("MMMM D, YYYY")
				: "";
		},
	},
	methods: {
		nextStep() {
			// Checks if amount entered amount in 'Other' field is at least $5.00 or higher; otherwise will show an error
			if (this.selectedAmountRadioButton == "Other") {
				if (this.otherAmount < 5) {
					this.donationSelectionError = false;
					this.donationSelectionErrorMessage = [
						"Minimum donation amount is $5.00",
					];
					return;
				} else {
					this.donationSelectionError = true;
					this.donationSelectionErrorMessage = null;
				}
			}

			if (this.$refs.stepOneForm.validate()) {
				this.store.setS1FormData({
					currency: this.currency,
					paymentFrequency: this.paymentFrequency,
					paymentStart: this.paymentStartDate,
					paymentStartFormatted: this.formattedPaymentDate,
					amount: this.donationAmount.toFixed(2),
					totalAmount: this.donationTotal.toFixed(2),
					coverFee: this.transactionFeeCheckbox,
					tribute: this.tributeCheckbox,
				});

				this.$emit("nextStepFromOne");
			}
		},
		maskCurrency(input) {
			let amount = parseFloat(input);

			if (amount != null) {
				// Clear amount input if negative number or invalid number
				if (amount < 0 || isNaN(amount)) {
					this.otherAmount = null;
					this.donationAmount = this.donationTotal = 0;
				} else {
					this.otherAmount = amount.toFixed(2);
				}
			}
		},
		selectOtherRadioButton() {
			this.$nextTick(() => {
				this.selectedAmountRadioButton = "Other";
			});
		},
		calculateDonation() {
			if (this.transactionFeeCheckbox) {
				this.donationTotal = this.calculateDonationWithFee();
			} else {
				this.donationTotal = this.donationAmount;
			}
		},
		calculateDonationWithFee() {
			return this.donationAmount + this.donationAmount * 0.025;
		},
		donationAmountLabel() {
			return this.currency === ""
				? "Your Gift: $" + this.donationTotal.toFixed(2)
				: "Your Gift: $" +
						this.donationTotal.toFixed(2) +
						" (" +
						this.currency +
						")";
		},
		ourMatchLabel() {
			if (! this.matchMultiplier) {
				return ""
			}
			const match = this.matchMultiplier * this.donationTotal
			// this.paymentFrequency !== 'One Time'
			return this.currency === ""
				? "Our Match: $" + match.toFixed(2)
				: "Our Match: $" +
						match.toFixed(2) +
						" (" +
						this.currency +
						")";
		},
		totalImpactLabel() {
			if (! this.matchMultiplier) {
				return ""
			}
			const total = (this.matchMultiplier * this.donationTotal) + this.donationTotal
			return this.currency === ""
				? "Total Impact: $" + total.toFixed(2)
				: "Total Impact: $" +
						total.toFixed(2) +
						" (" +
						this.currency +
						")";
		},
	},
};
</script>

<style lang="scss" scoped>
// Center radio buttons
.v-input--selection-controls .v-radio ::v-deep .v-label {
	flex: none !important;
}

// Override default styling for subheading text
.subheader-text {
	font-size: 0.77rem !important;
	height: 30px;
}

// Icon
div ::v-deep .theme--light.v-icon {
	color: $primary !important;
}

div ::v-deep h3.match-label {
	color: $primary !important;
}

// Override website styling for textfields
div ::v-deep .v-text-field input {
	border: none !important;
	background: none !important;
	font-size: 16px;
}

// Override website styling for tables
div ::v-deep .v-date-picker-table {
	margin-bottom: 10px;

	table {
		height: inherit !important;

		thead {
			tr {
				th {
					border: none !important;
					color: $secondary !important;
				}
			}
		}

		tbody {
			tr {
				td {
					border: none !important;
					padding: 3px 0px;
				}
			}
		}
	}
}
</style>
