<template>
	<v-container class="pl-12 secondary--text">
		<v-row justify="center">
			<div style="width: 380px">
				<v-col cols="12" align="left">
					<h2 v-if="store.s1FormData.tribute">Step 5: Verify and Donate</h2>

					<h2 v-else>Step 4: Verify and Donate</h2>

					<h3 class="mt-3">${{ store.s1FormData.totalAmount }}</h3>

					<span>Currency: {{ store.s1FormData.currency }}</span>

					<br />

					<span>
						Payment Frequency: {{ store.s1FormData.paymentFrequency }}
					</span>

					<br />

					<span v-if="store.s1FormData.paymentFrequency != 'One Time'">
						Payment Date:
						{{ store.s1FormData.paymentStartFormatted }}
					</span>
					<br v-if="store.s1FormData.paymentFrequency != 'One Time'" />

					<span>
						Cover transaction fee:
						{{ store.s1FormData.coverFee ? "Yes" : "No" }}
					</span>

					<v-col cols="12" class="mt-5 mb-3">
						<v-row> <h3>Donor Information</h3> </v-row>
						<v-row> {{ store.s2FormData.companyName }} </v-row>
						<v-row>
							{{ store.s2FormData.firstName }}
							{{ store.s2FormData.lastName }}
						</v-row>
						<v-row>
							{{ store.s2FormData.address }}, {{ store.s2FormData.address2 }}
						</v-row>
						<v-row>
							{{ store.s2FormData.city }}, {{ store.s2FormData.region }},
							{{ store.s2FormData.postalCode }}
						</v-row>
						<v-row><br /></v-row>
						<v-row> {{ store.s2FormData.phone }} </v-row>
						<v-row>
							{{ store.s2FormData.altPhone }}
						</v-row>
						<v-row class="mb-1">
							{{ store.s2FormData.email }}
						</v-row>
					</v-col>

					<v-col cols="12">
						<v-row> <h3>Payment Information</h3> </v-row>
						<v-row> Card Number: {{ maskedCardNumber() }} </v-row>
						<v-row> Expiry Date: {{ store.s3FormData.cardExpiry }} </v-row>
						<v-row> Cardholder Name: {{ store.s3FormData.cardHolder }} </v-row>
					</v-col>

					<v-col v-if="store.s1FormData.tribute" cols="12" class="mt-6">
						<v-row> <h3>Tribute Information</h3> </v-row>
						<v-row
							>Gift is:
							{{
								store.s2FormTributeData.tributeType == "Memorial"
									? "In Memory of"
									: "In Honor of"
							}}</v-row
						>
						<v-row>
							{{
								store.s2FormTributeData.tributeType == "Memorial"
									? store.s2FormTributeData.memorialFirstName +
									  " " +
									  store.s2FormTributeData.memorialLastName
									: store.s2FormTributeData.tributeFirstName +
									  " " +
									  store.s2FormTributeData.tributeLastName
							}}
						</v-row>

						<v-row><br /></v-row>

						<v-row>
							Notifcation:
							{{ store.s2FormTributeData.tributePreference }}
						</v-row>

						<v-row v-if="store.s2FormTributeData.tributePreference == 'Email'">
							{{ store.s2FormTributeData.tributeEmail }}
						</v-row>

						<v-row
							v-else-if="
								store.s2FormTributeData.tributePreference == 'Postal Mail'
							"
						>
							{{ store.s2FormTributeData.tributeAddress }},
							{{ store.s2FormTributeData.tributeAddress2 }}
							<br />
							{{ store.s2FormTributeData.tributeCity }},
							{{ store.s2FormTributeData.tributeRegion }},
							{{ store.s2FormTributeData.tributePostalCode }}
						</v-row>

						<v-row><br /></v-row>

						<v-row v-if="store.s2FormTributeData.tributeMessage">
							Message: {{ store.s2FormTributeData.tributeMessage }}
						</v-row>
					</v-col>

					<v-row justify="space-around mt-5 mb-0">
						<v-btn
							class="btn-text"
							large
							:disabled="donateBtnLoading"
							@click="previousStep()"
						>
							Back
						</v-btn>

						<v-btn
							id="pllenty-donate-btn"
							class="btn-text"
							color="primary"
							large
							:loading="donateBtnLoading"
							@click="nextStep()"
						>
							Donate Now
						</v-btn>
					</v-row>
				</v-col>
			</div>
		</v-row>
	</v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";

import { useFormDataStore } from "../../store/formData.js";

export default {
	name: "ConfirmationForm",
	data() {
		return {
			donateBtnLoading: false,
		};
	},
	setup() {
		const store = useFormDataStore();

		return {
			store,
		};
	},
	methods: {
		async generateRecaptchaToken() {
			let recaptchaObject = this.store.recaptchaObject;
			const gtoken = await recaptchaObject.execute("pllentyForm");

			return gtoken;
		},
		async submitForm() {
			let token = await this.generateRecaptchaToken();

			// console.log({
			// 	...this.store.s1FormData,
			// 	...this.store.s2FormData,
			// 	...this.store.s2FormTributeData,
			// 	...this.store.s3FormData,
			// 	queryString: window.location.search,
			// 	dt: moment(new Date(Date.now())).format(),
			// 	sandbox: this.store.testMode,
			// 	token: token,
			// 	campaignId: this.store.campaignId,
			// });

			await axios
				.post("https://api.pllenty.com/mafc/form/process", {
					...this.store.s1FormData,
					...this.store.s2FormData,
					...this.store.s2FormTributeData,
					...this.store.s3FormData,
					queryString: window.location.search,
					dt: moment(new Date(Date.now())).format(),
					sandbox: this.store.testMode,
					token: token,
					campaignId: this.store.campaignId,
				})
				.then((response) => {
					// console.log(response);
					this.store.setFormSubmissionData({
						status: response.data.transaction.status,
						merchantId: response.data.transaction.merchantId,
						errorMessage:
							response.data.transaction.message != undefined
								? response.data.transaction.message
								: null,
					});

					if (response.data.transaction.status == "Approved") {
						window[this.store.completedFn]({
							campaignId: this.store.campaignId,
							status: this.store.formSubmissionData.status,
							transactionId: this.store.formSubmissionData.merchantId,
							amount: parseInt(this.store.s1FormData.amount),
							type: this.store.s1FormData.paymentFrequency,
						});
					}
				});
		},
		async nextStep() {
			this.donateBtnLoading = true;

			await this.submitForm().then(() => {
				this.donateBtnLoading = false;

				this.$emit("formSubmitted");
			});
		},
		previousStep() {
			this.$emit("previousStep");
		},
		maskedCardNumber() {
			let cardNumber = this.store.s3FormData.cardNumber;

			if (cardNumber) {
				return `${"*".repeat(cardNumber.length - 4)}${cardNumber.substr(
					cardNumber.length - 4
				)}`;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.btn-text {
	font-weight: bold;
}
</style>
