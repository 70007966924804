import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { createPinia, PiniaVuePlugin } from "pinia";
import { VueMaskDirective } from "v-mask";
import VueCustomElement from "vue-custom-element";

Vue.directive("mask", VueMaskDirective);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.config.productionTip = false;

Vue.use(VueCustomElement);

Vue.customElement("pllenty-form", App, {
	beforeCreateVueInstance(root) {
		root.router = router;
		root.pinia = pinia;
		root.vuetify = vuetify;

		return root;
	},

	props: [
		"campaignId",
		"restrictedAccess",
		"testMode",
		"paramsFn",
		"completedFn",
	],
});
