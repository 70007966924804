<template>
	<v-container class="secondary--text">
		<v-row justify="center">
			<v-col cols="10" align="center">
				<h2>Thank You For Your Donation</h2>

				<table class="mt-5">
					<tbody class="secondary--text">
						<tr>
							<td>Status</td>
							<td class="pl-5">
								<v-icon color="success"> mdi-checkbox-marked-circle </v-icon>
								{{ store.formSubmissionData.status }}
							</td>
						</tr>

						<tr>
							<td>Transaction ID</td>
							<td class="pl-5">{{ store.formSubmissionData.merchantId }}</td>
						</tr>

						<tr>
							<td>Amount</td>
							<td class="pl-5">${{ store.s1FormData.amount }}</td>
						</tr>

						<tr>
							<td>Frequency</td>
							<td class="pl-5">{{ store.s1FormData.paymentFrequency }}</td>
						</tr>

						<tr v-show="store.s1FormData.paymentFrequency != 'One Time'">
							<td>Payment Start Date</td>
							<td class="pl-5">{{ store.s1FormData.paymentStartFormatted }}</td>
						</tr>
					</tbody>
				</table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { useFormDataStore } from "../../store/formData.js";

export default {
	name: "EndForm",
	setup() {
		const store = useFormDataStore();

		return {
			store,
		};
	},
};
</script>

<style lang="scss" scoped></style>
