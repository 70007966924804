<template>
	<div>
		<v-stepper v-model="currentFormStep" elevation="0" alt-labels>
			<v-container class="pa-0">
				<v-stepper-header
					class="stepper-header"
					v-show="currentFormStep !== 1"
					sm="6"
				>
					<template v-for="step in numberOfSteps">
						<v-stepper-step
							class="pb-3"
							:key="`${step}` + '-step'"
							:complete="currentFormStep > step"
							:step="step"
						>
						</v-stepper-step>

						<v-divider v-if="step !== numberOfSteps" :key="step"></v-divider>
					</template>
				</v-stepper-header>
			</v-container>

			<S1Form
				@nextStepFromOne="nextStep"
				:loadDonationParams="store.formParams"
			/>

			<S2Form @nextStepFromTwo="nextStep" @previousStepFromTwo="previousStep" />

			<S3Form
				@nextStepFromThree="nextStep"
				@previousStepFromThree="previousStep"
			/>

			<S4Form
				@formSubmitted="validateFormSubmission"
				@nextStepFromFour="nextStep"
				@previousStepFromFour="previousStep"
			/>

			<S5Form
				@formSubmitted="validateFormSubmission"
				@previousStepFromFive="previousStep"
			/>

			<S6Form />

			<v-footer color="white">
				<v-col align="center" class="py-0 my-0">
					<v-row
						class="pt-2"
						justify="center"
						no-gutters
						style="margin-bottom: -3px"
					>
						<a href="https://pllenty.com/" target="_blank">
							<img
								src="https://mafc-forms.pllenty.com/img/pllenty-signature-115.gif"
								alt="Powered by Pllenty"
								style="max-height: 50px"
							/>
						</a>
					</v-row>

					<v-row justify="center" no-gutters>
						<span class="error-font-size grey--text">
							This site is protected by reCAPTCHA and the Google
							<a href="https://policies.google.com/privacy" target="_blank">
								Privacy Policy
							</a>
							and
							<a href="https://policies.google.com/terms" target="_blank">
								Terms of Service
							</a>
							apply.
						</span>
					</v-row>
				</v-col>
			</v-footer>
		</v-stepper>
	</div>
</template>

<script>
import { useFormDataStore } from "../store/formData.js";

import S1Form from "./FormSteps/S1Form.vue";
import S2Form from "./FormSteps/S2Form.vue";
import S3Form from "./FormSteps/S3Form.vue";
import S4Form from "./FormSteps/S4Form.vue";
import S5Form from "./FormSteps/S5Form.vue";
import S6Form from "./FormSteps/S6Form.vue";
import { load } from "recaptcha-v3";

export default {
	name: "Form",
	setup() {
		const store = useFormDataStore();

		return {
			store,
		};
	},
	data() {
		return {
			currentFormStep: 1,
			numberOfSteps: 4,
			stepLabels: [],
			formStatus: null,
			formMerchantId: null,
		};
	},
	components: {
		S1Form,
		S2Form,
		S3Form,
		S4Form,
		S5Form,
		S6Form,
	},
	props: {
		campaignId: {
			type: String,
			default: "",
		},
		restrictedAccess: {
			type: String,
			default: "",
		},
		testMode: {
			type: Boolean,
		},
		paramsFn: {
			type: String,
		},
		completedFn: {
			type: String,
		},
	},
	mounted() {
		this.loadRecaptcha();

		this.store.setCampaignId(this.campaignId);

		this.store.setRestrictedAccess(this.restrictedAccess);

		this.store.setTestMode(this.testMode);

		this.store.setFormParams(window[this.paramsFn]());

		this.store.setCompletedFn(this.completedFn);
	},
	created() {},
	methods: {
		async loadRecaptcha() {
			const recaptcha = await load(this.store.recaptchaSiteKey, {
				autoHideBadge: true,
			});

			this.store.setRecaptchaObject(recaptcha);
		},
		scrollToTop() {
			const el = this.$el.querySelector(".stepper-header");

			this.$nextTick(() => {
				this.$vuetify.goTo(el, { offset: 125 });
			});
		},
		nextStep() {
			this.scrollToTop();

			if (
				this.currentFormStep == 4 &&
				!this.store.formParams.showCompleteStep
			) {
				this.currentFormStep = 7;
			} else {
				if (this.currentFormStep == 1 && this.store.s1FormData.tribute) {
					this.numberOfSteps = 5;
				} else if (this.currentFormStep == 1) {
					this.numberOfSteps = 4;
				}

				this.currentFormStep++;
			}
		},
		previousStep() {
			this.scrollToTop();

			this.currentFormStep--;
		},
		validateFormSubmission() {
			if (this.store.formSubmissionData.status == "Declined") {
				this.previousStep();
			} else {
				this.nextStep();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.stepper-header {
	box-shadow: none;
}
.error-font-size {
	font-size: 12px;
}
</style>
