<template>
	<v-stepper-items>
		<v-stepper-content class="py-0 px-0" step="6">
			<EndForm />
		</v-stepper-content>
	</v-stepper-items>
</template>

<script>
import EndForm from "../Forms/EndForm.vue";

export default {
	name: "S6Form",
	data() {
		return {};
	},
	components: {
		EndForm,
	},
	methods: {},
};
</script>

<style lang="scss" scoped></style>
