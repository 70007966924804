<template>
	<v-stepper-items>
		<v-stepper-content class="py-0 px-0" step="3">
			<DonorForm
				v-if="store.s1FormData.tribute"
				@nextStep="nextStep"
				@previousStep="previousStep"
			/>

			<CreditCardForm
				v-else
				@nextStep="nextStep"
				@previousStep="previousStep"
			/>
		</v-stepper-content>
	</v-stepper-items>
</template>

<script>
import DonorForm from "../Forms/DonorForm.vue";
import CreditCardForm from "../Forms/CreditCardForm.vue";

import { useFormDataStore } from "../../store/formData.js";

export default {
	name: "S3Form",
	data() {
		return {};
	},
	setup() {
		const store = useFormDataStore();

		return {
			store,
		};
	},
	components: {
		DonorForm,
		CreditCardForm,
	},
	methods: {
		nextStep() {
			this.$emit("nextStepFromThree");
		},
		previousStep() {
			this.$emit("previousStepFromThree");
		},
	},
};
</script>

<style lang="scss" scoped></style>
