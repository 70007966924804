<template>
	<v-app class="app">
		<v-main>
			<Form
				:campaignId="campaignId"
				:restrictedAccess="restrictedAccess"
				:testMode="testMode"
				:paramsFn="paramsFn"
				:completedFn="completedFn"
			/>
		</v-main>
	</v-app>
</template>

<script>
import Form from "./components/Form.vue";

export default {
	name: "App",
	data: () => ({}),
	components: {
		Form,
	},
	props: {
		campaignId: {
			type: String,
			default: "",
		},
		restrictedAccess: {
			type: String,
			default: "",
		},
		testMode: {
			type: Boolean,
		},
		paramsFn: {
			type: undefined,
		},
		completedFn: {
			type: undefined,
		},
	},
};
</script>

<style lang="scss">
.v-application--wrap {
	min-height: unset !important;
}

@font-face {
	font-family: "MuseoSans-300";
	src: local("MuseoSans-300"),
		url("./assets/fonts/MuseoSans-300.ttf") format("truetype");
}

#app {
	font-family: "MuseoSans-300", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>
