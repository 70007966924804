<template>
	<v-stepper-items>
		<v-stepper-content class="py-0 px-0" step="4">
			<CreditCardForm
				v-if="store.s1FormData.tribute"
				@nextStep="nextStep"
				@previousStep="previousStep"
			/>

			<ConfirmationForm
				v-else
				@formSubmitted="formSubmitted"
				@previousStep="previousStep"
			/>
		</v-stepper-content>
	</v-stepper-items>
</template>

<script>
import CreditCardForm from "../Forms/CreditCardForm.vue";
import ConfirmationForm from "../Forms/ConfirmationForm.vue";

import { useFormDataStore } from "../../store/formData.js";

export default {
	name: "S4Form",
	data() {
		return {};
	},
	setup() {
		const store = useFormDataStore();

		return {
			store,
		};
	},
	components: {
		CreditCardForm,
		ConfirmationForm,
	},
	methods: {
		nextStep() {
			this.$emit("nextStepFromFour");
		},
		previousStep() {
			this.$emit("previousStepFromFour");
		},
		formSubmitted() {
			this.$emit("formSubmitted");
		},
	},
};
</script>

<style lang="scss" scoped>
.btn-text {
	font-weight: bold;
}
</style>
