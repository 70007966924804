<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 302 217.543">
    <path fill="#fff" d="M298.216 175.608h-295V44.94h295v130.667z" />
    <path
      fill="#f7b600"
      d="M3.216 170.932H297.92l1.515 29.055-9.626 13.226H13.25L3.774 201.4l-.558-30.468z"
    />
    <path
      fill="#1a1f71"
      d="M4.147 48.305h294.606l-.538-30.614L289.8 3.108H13.25L4.356 15.086l-.21 33.22zM149.14 75.622l-14.572 68.15H116.95l14.554-68.15h17.636zm74.184 44.005l9.287-25.586 5.313 25.586h-14.6zm19.678 24.145h16.285l-14.243-68.15h-15.038c-3.383 0-6.244 1.966-7.5 5l-26.465 63.15h18.526l3.698-10.168h22.59l2.147 10.168zm-46.02-22.246c.072-17.98-24.877-18.984-24.725-27.02.09-2.438 2.382-5.047 7.488-5.7 2.525-.327 9.49-.597 17.392 3.04l3.096-14.448c-4.262-1.537-9.688-3.018-16.503-3.018-17.428 0-29.7 9.26-29.79 22.525-.112 9.815 8.755 15.292 15.437 18.552 6.86 3.338 9.166 5.488 9.135 8.467-.044 4.574-5.488 6.596-10.533 6.67-8.868.133-14.018-2.395-18.114-4.305l-3.2 14.94c4.123 1.887 11.73 3.525 19.606 3.623 18.526.001 30.66-9.157 30.712-23.325m-73.044-45.904l-28.562 68.15H76.75l-14.073-54.39c-.847-3.33-1.59-4.574-4.167-5.982-4.26-2.303-11.24-4.45-17.397-5.803l.414-1.977h29.99c3.82 0 7.257 2.553 8.163 6.952l7.405 39.43 18.338-46.383h18.513z"
    />
    <path
      fill="#fefefe"
      d="M279.64.635H22.395c-11.55 0-20.96 9.417-20.96 20.978v174.23c0 11.3 9.474 20.793 20.772 20.793h257.617c11.3 0 20.772-9.483 20.772-20.793V21.613C300.598 10.052 291.19.635 279.64.635zm17.315 195.282c-.001 9.207-7.88 17.074-17.093 17.075l-152.602.002c-33.086 0-66.966 0-105.087-.002-9.214-.001-17.092-7.868-17.093-17.075L5.073 21.454a15.04 15.04 0 0 1 .328-3.093c1.776-8.47 8.77-14.084 17.547-14.084h256.136c8.776 0 15.77 5.615 17.547 14.084.213 1.016.328 2.048.328 3.093l-.005 174.463z"
    />
  </svg>
</template>

<script>
export default {
  name: "CardVisa",
};
</script>

<style></style>
