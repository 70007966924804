<template>
	<v-container class="pt-0">
		<v-row justify="center">
			<v-col cols="11" class="px-0">
				<h2 class="mb-3">Step 2: In Honour/In Memory Information</h2>

				<v-form ref="stepTwoTributeForm" lazy-validation>
					<span
						class="subheader-text pa-0"
						v-html="'This gift is:&nbsp;*'"
					></span>

					<v-radio-group
						v-model="tributeType"
						class="ma-0 mb-3 pa-0 error-field"
						row
						:rules="rules.tributeType"
						:hide-details="true"
					>
						<v-radio :label="'In Memory of'" :value="'Memorial'"></v-radio>

						<v-radio :label="'In Honour of'" :value="'Honor'"></v-radio>
					</v-radio-group>

					<div v-if="tributeType">
						<v-row v-if="tributeType == 'Memorial'" class="px-3 my-0">
							<p class="mb-2"><b>In Memory of</b></p>
						</v-row>

						<v-row v-if="tributeType == 'Memorial'" class="my-0">
							<v-text-field
								label="First Name *"
								id="Memorial First Name"
								class="px-3 error-field"
								v-model.trim="memorialFirstName"
								:rules="rules.memorialFirstName"
								v-on:keypress="isLetter($event)"
							></v-text-field>

							<v-text-field
								label="Last Name *"
								id="Memorial Last Name"
								class="px-3 error-field"
								v-model.trim="memorialLastName"
								:rules="rules.memorialLastName"
								v-on:keypress="isLetter($event)"
							></v-text-field>
						</v-row>

						<v-row v-if="tributeType == 'Memorial'" class="mt-1 px-3 pb-1">
							<p><b>Notification Contact Information</b></p>
						</v-row>

						<v-row v-if="tributeType == 'Honor'" class="px-3 my-0">
							<p class="mb-2"><b>In Honour of</b></p>
						</v-row>

						<v-row v-if="tributeType == 'Honor'" class="my-0">
							<v-text-field
								label="First Name *"
								id="First Name"
								class="px-3 error-field"
								v-model.trim="tributeFirstName"
								:rules="rules.tributeFirstName"
								v-on:keypress="isLetter($event)"
							></v-text-field>

							<v-text-field
								label="Last Name *"
								id="Last Name"
								class="px-3 error-field"
								v-model.trim="tributeLastName"
								:rules="rules.tributeLastName"
								v-on:keypress="isLetter($event)"
							></v-text-field>
						</v-row>

						<span
							class="subheader-text pa-0"
							v-html="'Notification preference:&nbsp;*'"
						></span>

						<v-radio-group
							v-model="tributePreference"
							class="ma-0 pa-0 mt-1 mb-3 error-field"
							row
							mandatory
							hide-details
						>
							<v-radio :label="'Email'" :value="'Email'"></v-radio>

							<v-radio :label="'Postal Mail'" :value="'Postal Mail'"></v-radio>

							<v-radio :label="'Do Not Notify'" :value="'Do Not Notify'"></v-radio>
						</v-radio-group>

						<v-row v-if="tributeType == 'Memorial'" class="my-0">
							<v-text-field
								:label="
									tributePreference == 'Do Not Notify' ? 'First Name' : 'First Name *'
								"
								id="First Name"
								class="px-3 error-field"
								v-model.trim="tributeFirstName"
								:rules="rules.tributeFirstName"
								v-on:keypress="isLetter($event)"
							></v-text-field>

							<v-text-field
								:label="
									tributePreference == 'Do Not Notify' ? 'Last Name' : 'Last Name *'
								"
								id="Last Name"
								class="px-3 error-field"
								v-model.trim="tributeLastName"
								:rules="rules.tributeLastName"
								v-on:keypress="isLetter($event)"
							></v-text-field>
						</v-row>

						<v-row class="my-0">
							<v-col class="mb-0 py-0" cols="12" sm="6" md="6">
								<v-text-field
									:label="tributePreference == 'Email' ? 'Email *' : 'Email'"
									id="Email"
									class="error-field"
									v-model.trim="email"
									:rules="rules.email"
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="my-0">
							<v-col class="mb-0 py-0" cols="12" sm="6" md="6">
								<v-select
									:item-color="'secondary'"
									label="Country *"
									id="Country"
									class="error-field"
									v-model="country"
									:items="countryOptions"
								></v-select>
							</v-col>
						</v-row>

						<v-row class="my-0">
							<v-text-field
								:label="
									tributePreference == 'Postal Mail' ? 'Address *' : 'Address'
								"
								id="Address"
								class="px-3 error-field"
								v-model.trim="address"
								:rules="rules.address"
							></v-text-field>

							<v-text-field
								label="App, Suite, ..."
								id="App/Suite"
								class="px-3"
								v-model.trim="app_suite"
							></v-text-field>
						</v-row>

						<v-row class="my-0">
							<v-text-field
								:label="tributePreference == 'Postal Mail' ? 'City *' : 'City'"
								id="City"
								class="px-3 error-field"
								v-model.trim="city"
								:rules="rules.city"
							></v-text-field>

							<v-col class="pb-0" cols="12" sm="6" md="6">
								<v-select
									v-if="
										country == 'Canada' || country == 'United States'
											? true
											: false
									"
									:label="
										tributePreference == 'Postal Mail'
											? 'Province/State *'
											: 'Province/State'
									"
									class="error-field py-0"
									v-model="state_province"
									:rules="rules.state_province"
									:items="
										country == 'Canada'
											? canadaProvinceOptions
											: unitedStatesStateOptions
									"
								></v-select>

								<v-text-field
									v-else
									:label="
										tributePreference == 'Postal Mail'
											? 'Province/State *'
											: 'Province/State'
									"
									class="py-0"
									v-model="state_provice_TF"
									:rules="rules.state_provice_TF"
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="my-0">
							<v-col class="mb-0 py-0" cols="12" sm="6" md="6">
								<v-text-field
									:label="
										tributePreference == 'Postal Mail'
											? 'Postal Code/Zip *'
											: 'Postal Code/Zip'
									"
									id="Postal Code/Zip"
									class="error-field"
									v-model="zip_postalCode"
									v-mask="
										country == 'Canada'
											? 'A#A #A#'
											: country == 'United States'
											? '#########'
											: null
									"
									:rules="rules.zip_postalCode"
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="ma-0">
							<v-textarea
								:label="
									tributePreference == 'Do Not Notify'
										? 'Notifcation Message'
										: 'Notifcation Message *'
								"
								id="Notifcation Message"
								v-model="tributeMessage"
								auto-grow
								rows="3"
								:rules="rules.tributeMessage"
							></v-textarea>
						</v-row>
					</div>
				</v-form>

				<v-row class="mt-3">
					<v-col cols="6" align="center">
						<v-btn class="btn-text" large @click="previousStep()"> Back </v-btn>
					</v-col>

					<v-col cols="6" align="center">
						<v-btn class="btn-text" color="primary" large @click="nextStep()">
							Next
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { useFormDataStore } from "../../store/formData.js";

export default {
	name: "TributeForm",
	data() {
		return {
			tributeType: "Memorial",
			memorialFirstName: "",
			memorialLastName: "",
			tributeFirstName: "",
			tributeLastName: "",
			address: "",
			app_suite: "",
			city: "",
			zip_postalCode: "",
			country: "Canada",
			state_province: "",
			state_provice_TF: "",
			email: "",
			tributePreference: "",
			tributeMessage: "",
			submitted: false,
			rules: {
				tributeType: [],
				memorialFirstName: [],
				memorialLastName: [],
				tributeFirstName: [],
				tributeLastName: [],
				tributeMessage: [],
				address: [],
				city: [],
				zip_postalCode: [],
				state_province: [],
				state_provice_TF: [],
				email: [],
			},
		};
	},
	setup() {
		const store = useFormDataStore();

		return {
			store,
		};
	},
	watch: {
		zip_postalCode() {
			if (this.country != "United States") {
				this.zip_postalCode = this.zip_postalCode.toUpperCase();
			}
		},
		country() {
			this.zip_postalCode = "";
			this.rules.zip_postalCode = [];
		},
		phone(currentPhoneNumber) {
			if (currentPhoneNumber == "") {
				this.rules.phoneNumber = [];
			}
		},
		async tributePreference() {
			if (this.submitted) {
				await this.setFieldValidationRules();

				this.$refs.stepTwoTributeForm.validate();
			}
		},
		tributeType() {
			if (this.submitted) {
				this.$refs.stepTwoTributeForm.resetValidation();
			}
		},
	},
	computed: {
		countryOptions() {
			return Object.keys(this.store.countryOptions);
		},
		canadaProvinceOptions() {
			return Object.keys(this.store.canadaProvinceOptions);
		},
		unitedStatesStateOptions() {
			return Object.keys(this.store.unitedStatesStateOptions);
		},
	},
	methods: {
		setFieldValidationRules() {
			this.rules.tributeType = [(v) => !!v || "Required"];
			this.rules.memorialFirstName = [(v) => !!v.trim() || "Required"];
			this.rules.memorialLastName = [(v) => !!v.trim() || "Required"];
			this.rules.tributeFirstName =
				this.tributeType == "Memorial" && this.tributePreference == "Do Not Notify"
					? []
					: [(v) => !!v.trim() || "Required"];
			this.rules.tributeLastName =
				this.tributeType == "Memorial" && this.tributePreference == "Do Not Notify"
					? []
					: [(v) => !!v.trim() || "Required"];
			this.rules.tributeMessage =
				this.tributePreference == "Email" ||
				this.tributePreference == "Postal Mail"
					? [(v) => !!v.trim() || "Required"]
					: [];
			this.rules.address =
				this.tributePreference == "Postal Mail"
					? [(v) => !!v.trim() || "Required"]
					: [];
			this.rules.city =
				this.tributePreference == "Postal Mail"
					? [(v) => !!v.trim() || "Required"]
					: [];

			let uKPostalCodeRegex = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/;
			this.rules.zip_postalCode =
				this.tributePreference == "Postal Mail"
					? this.country == "Canada"
						? [
								(v) => !!v || "Required",
								(v) => (v || "").length >= 7 || "Invalid postal code",
						  ]
						: this.country == "United States"
						? [
								(v) => !!v || "Required",
								(v) => v.length == 5 || v.length == 9 || "Invalid zip code",
						  ]
						: this.country == "Switzerland"
						? [
								(v) => !!v || "Required",
								(v) => (v || "").length >= 4 || "Invalid postal/zip code",
						  ]
						: this.country == "United Kingdom"
						? [
								(v) => !!v || "Required",
								(v) => uKPostalCodeRegex.test(v) || "Invalid postal/zip code",
						  ]
						: [
								(v) => !!v || "Required",
								(v) => (v || "").length >= 5 || "Invalid postal/zip code",
						  ]
					: [];

			let emailRegex =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
			this.rules.email =
				this.tributePreference == "Email"
					? [
							(v) => !!v || "Required",
							(v) => emailRegex.test(v) || "Invalid e-mail",
					  ]
					: [];

			if (this.country == "Canada" || this.country == "United States") {
				this.rules.state_province =
					this.tributePreference == "Postal Mail"
						? [(v) => !!v || "Required"]
						: [];
				this.rules.state_provice_TF = [];
			} else {
				this.rules.state_provice_TF =
					this.tributePreference == "Postal Mail"
						? [(v) => !!v || "Required"]
						: [];
				this.rules.state_province = [];
			}
		},
		async nextStep() {
			this.submitted = true;

			// Wait for field validation rules to be set before validating form
			await this.setFieldValidationRules();

			// console.log({
			// 	tributeType: this.tributeType,
			// 	memorialFirstName: this.memorialFirstName.trim(),
			// 	memorialLastName: this.memorialLastName.trim(),
			// 	tributeFirstName: this.tributeFirstName.trim(),
			// 	tributeLastName: this.tributeLastName.trim(),
			// 	tributeAddress: this.address.trim(),
			// 	tributeAddress2: this.app_suite.trim(),
			// 	tributeCity: this.city.trim(),
			// 	tributePostalCode: this.zip_postalCode,
			// 	tributeCountry: this.country,
			// 	tributeRegion:
			// 		this.country == "Canada"
			// 			? this.store.canadaProvinceOptions[this.state_province]
			// 			: this.country == "United States"
			// 			? this.store.unitedStatesStateOptions[this.state_province]
			// 			: this.state_provice_TF,
			// 	tributeEmail: this.email.trim(),
			// 	tributePreference: this.tributePreference,
			// 	tributeMessage: this.tributeMessage.trim(),
			// });

			if (this.$refs.stepTwoTributeForm.validate()) {
				this.store.setS2FormTributeData({
					tributeType: this.tributeType,
					memorialFirstName: this.memorialFirstName.trim(),
					memorialLastName: this.memorialLastName.trim(),
					tributeFirstName: this.tributeFirstName.trim(),
					tributeLastName: this.tributeLastName.trim(),
					tributeAddress: this.address.trim(),
					tributeAddress2: this.app_suite.trim(),
					tributeCity: this.city.trim(),
					tributePostalCode: this.zip_postalCode,
					tributeCountry: this.country,
					tributeRegion:
						this.country == "Canada"
							? this.store.canadaProvinceOptions[this.state_province]
							: this.country == "United States"
							? this.store.unitedStatesStateOptions[this.state_province]
							: this.state_provice_TF,
					tributeEmail: this.email.trim(),
					tributePreference: this.tributePreference,
					tributeMessage: this.tributeMessage.trim(),
				});

				this.$emit("nextStep");
			} else {
				// Scroll to first error
				this.$nextTick(() => {
					const el = this.$el.querySelector(
						".v-messages.error--text:first-of-type"
					);
					this.$vuetify.goTo(el, { offset: 125 });

					return;
				});
			}
		},
		previousStep() {
			this.$emit("previousStep");
		},
		isLetter(e) {
			let char = String.fromCharCode(e.keyCode); // Get the character
			if (/^[A-Za-z-]+$/.test(char)) return true; // Match with regex
			else e.preventDefault(); // If not match, don't add to input text
		},
	},
};
</script>

<style lang="scss" scoped>
.subheader-text {
	font-size: 0.8rem !important;
	height: 30px;
}

// Icon
div ::v-deep .theme--light.v-icon {
	color: $primary !important;
}

// Override RR website styling for textfields
div ::v-deep .v-text-field input {
	border: none !important;
	background: none !important;
	font-size: 16px;
}
</style>
