<template>
	<v-container class="pt-0">
		<v-row justify="center">
			<v-col cols="11" class="px-0">
				<h2 v-if="store.s1FormData.tribute">Step 3: Donor Information</h2>

				<h2 v-else>Step 2: Donor Information</h2>

				<p class="mb-3">
					Please fill out your information below. It will help us provide you
					with a tax receipt for your donation.
				</p>

				<v-form ref="stepTwoForm" lazy-validation>
					<span
						class="subheader-text pa-0"
						v-html="
							'Is it an individual or an organization/church donation?&nbsp;*'
						"
					>
					</span>
					<v-radio-group
						v-model="donationType"
						class="ma-0 pa-0 mt-1 mb-3 error-field"
						mandatory
						row
						hide-details
					>
						<v-radio :label="'Individual'" :value="'Personal'"></v-radio>
						<v-radio
							:label="'Organization/Church'"
							:value="'Company'"
						></v-radio>
					</v-radio-group>

					<v-row class="my-0" v-show="donationType == 'Company'">
						<v-text-field
							label="Organization/Church Name *"
							id="Organization Name"
							class="px-3 error-field"
							v-model.trim="companyName"
							:rules="rules.companyName"
						></v-text-field>
					</v-row>

					<v-row class="my-0">
						<v-text-field
							label="First Name *"
							id="First Name"
							class="px-3 error-field"
							v-model.trim="firstName"
							:rules="rules.firstName"
							v-on:keypress="isLetter($event)"
						></v-text-field>

						<v-text-field
							label="Last Name *"
							id="Last Name"
							class="px-3 error-field"
							v-model.trim="lastName"
							:rules="rules.lastName"
							v-on:keypress="isLetter($event)"
						></v-text-field>
					</v-row>

					<v-row class="my-0">
						<v-col class="mb-0 py-0" cols="12" sm="6" md="6">
							<v-select
								:item-color="'secondary'"
								label="Country *"
								id="Country"
								class="error-field"
								v-model="country"
								:items="countryOptions"
							></v-select>
						</v-col>
					</v-row>

					<v-row class="my-0">
						<v-text-field
							label="Address *"
							id="Address"
							class="px-3 error-field"
							v-model.trim="address"
							:rules="rules.address"
						></v-text-field>

						<v-text-field
							label="App, Suite, ..."
							id="App/Suite"
							class="px-3"
							v-model.trim="app_suite"
						></v-text-field>
					</v-row>

					<v-row class="my-0">
						<v-text-field
							label="City *"
							id="City"
							class="px-3 error-field"
							v-model.trim="city"
							:rules="rules.city"
						></v-text-field>

						<v-col class="pb-0" cols="12" sm="6" md="6">
							<v-select
								v-if="
									country == 'Canada' || country == 'United States'
										? true
										: false
								"
								label="Province/State *"
								class="error-field py-0"
								v-model="state_province"
								:rules="rules.state_province"
								:items="
									country == 'Canada'
										? canadaProvinceOptions
										: unitedStatesStateOptions
								"
							></v-select>

							<v-text-field
								v-else
								label="Province/State *"
								class="py-0"
								v-model="state_provice_TF"
								:rules="rules.state_provice_TF"
							></v-text-field>
						</v-col>
					</v-row>

					<v-row class="my-0">
						<v-col class="mb-0 py-0" cols="12" sm="6" md="6">
							<v-text-field
								label="Postal Code/Zip *"
								id="Postal Code/Zip"
								class="error-field"
								v-model="zip_postalCode"
								v-mask="
									country == 'Canada'
										? 'A#A #A#'
										: country == 'United States'
										? '#########'
										: null
								"
								:rules="rules.zip_postalCode"
							></v-text-field>
						</v-col>
					</v-row>

					<v-row class="my-0">
						<v-text-field
							label="Phone"
							id="Phone"
							class="px-3 error-field"
							v-model="phone"
							v-mask="'(###) ###-####'"
							:rules="rules.phoneNumber"
						></v-text-field>

						<v-text-field
							label="Email *"
							id="Email"
							class="px-3 error-field"
							v-model.trim="email"
							:rules="rules.email"
						></v-text-field>
					</v-row>

					<v-row class="ma-0" v-if="store.restrictedAccess == 'true'">
						<v-text-field
							label="Unlisted and/or Non-Canadian Missionary *"
							class="error-field"
							v-model.trim="restrictedAccessName"
							:rules="rules.restrictedAccessName"
						></v-text-field>
					</v-row>
					<v-row v-if="store.formParams != null && !store.formParams.hideCommentField" class="ma-0">
						<v-textarea
							label="Comments"
							id="Comments"
							v-model="comments"
							auto-grow
							rows="1"
						></v-textarea>
					</v-row>
				</v-form>

				<v-row class="mt-3">
					<v-col cols="6" align="center">
						<v-btn class="btn-text" large @click="previousStep()"> Back </v-btn>
					</v-col>
					<v-col cols="6" align="center">
						<v-btn class="btn-text" color="primary" large @click="nextStep()">
							Next
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { useFormDataStore } from "../../store/formData.js";

export default {
	name: "DonorForm",
	data() {
		return {
			donationType: "",
			companyName: "",
			firstName: "",
			lastName: "",
			address: "",
			app_suite: "",
			city: "",
			zip_postalCode: "",
			country: "Canada",
			state_province: "",
			state_provice_TF: "",
			phone: "",
			email: "",
			restrictedAccessName: "",
			comments: "",
			rules: {
				companyName: [],
				firstName: [],
				lastName: [],
				address: [],
				city: [],
				zip_postalCode: [],
				state_province: [],
				state_provice_TF: [],
				phoneNumber: [],
				email: [],
				restrictedAccessName: [],
			},
		};
	},
	setup() {
		const store = useFormDataStore();

		return {
			store,
		};
	},
	watch: {
		zip_postalCode() {
			if (this.country != "United States") {
				this.zip_postalCode = this.zip_postalCode.toUpperCase();
			}
		},
		country() {
			this.zip_postalCode = "";
			this.rules.zip_postalCode = [];
		},
		phone(currentPhoneNumber) {
			if (currentPhoneNumber == "") {
				this.rules.phoneNumber = [];
			}
		},
	},
	computed: {
		countryOptions() {
			return Object.keys(this.store.countryOptions);
		},
		canadaProvinceOptions() {
			return Object.keys(this.store.canadaProvinceOptions);
		},
		unitedStatesStateOptions() {
			return Object.keys(this.store.unitedStatesStateOptions);
		},
	},
	methods: {
		setFieldValidationRules() {
			this.rules.companyName =
				this.donationType == "Company" ? [(v) => !!v.trim() || "Required"] : [];
			this.rules.firstName = [(v) => !!v.trim() || "Required"];
			this.rules.lastName = [(v) => !!v.trim() || "Required"];
			this.rules.address = [(v) => !!v.trim() || "Required"];
			this.rules.city = [(v) => !!v.trim() || "Required"];
			this.rules.restrictedAccessName =
				this.store.restrictedAccess == "true"
					? [(v) => !!v.trim() || "Required"]
					: [];

			let uKPostalCodeRegex = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/;
			this.rules.zip_postalCode =
				this.country == "Canada"
					? [
							(v) => !!v || "Required",
							(v) => (v || "").length >= 7 || "Invalid postal code",
					  ]
					: this.country == "United States"
					? [
							(v) => !!v || "Required",
							(v) => v.length == 5 || v.length == 9 || "Invalid zip code",
					  ]
					: this.country == "Switzerland"
					? [
							(v) => !!v || "Required",
							(v) => (v || "").length >= 4 || "Invalid postal/zip code",
					  ]
					: this.country == "United Kingdom"
					? [
							(v) => !!v || "Required",
							(v) => uKPostalCodeRegex.test(v) || "Invalid postal/zip code",
					  ]
					: [
							(v) => !!v || "Required",
							(v) => (v || "").length >= 5 || "Invalid postal/zip code",
					  ];

			let emailRegex =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
			this.rules.email = [
				(v) => !!v || "Required",
				(v) => emailRegex.test(v) || "Invalid e-mail",
			];

			if (this.phone != "") {
				this.rules.phoneNumber = [
					(v) => (v || "").length >= 14 || "Invalid phone number",
				];
			}

			if (this.country == "Canada" || this.country == "United States") {
				this.rules.state_province = [(v) => !!v || "Required"];
				this.rules.state_provice_TF = [];
			} else {
				this.rules.state_provice_TF = [(v) => !!v || "Required"];
				this.rules.state_province = [];
			}
		},
		async nextStep() {
			// Wait for field validation rules to be set before validating form
			await this.setFieldValidationRules();

			// console.log({
			// 	donationType: this.donationType,
			// 	companyName: this.companyName.trim(),
			// 	firstName: this.firstName.trim(),
			// 	lastName: this.lastName.trim(),
			// 	address: this.address.trim(),
			// 	address2: this.app_suite.trim(),
			// 	city: this.city.trim(),
			// 	postalCode: this.zip_postalCode,
			// 	country: this.country,
			// 	region:
			// 		this.country == "Canada"
			// 			? this.store.canadaProvinceOptions[this.state_province]
			// 			: this.country == "United States"
			// 			? this.store.unitedStatesStateOptions[this.state_province]
			// 			: this.state_provice_TF,
			// 	phone: this.phone,
			// 	email: this.email.trim(),
			// 	comments: this.comments.trim(),
			// 	restrictedAccessName: this.restrictedAccessName.trim(),
			// });

			if (this.$refs.stepTwoForm.validate()) {
				this.store.setS2FormData({
					donationType: this.donationType,
					companyName: this.companyName.trim(),
					firstName: this.firstName.trim(),
					lastName: this.lastName.trim(),
					address: this.address.trim(),
					address2: this.app_suite.trim(),
					city: this.city.trim(),
					postalCode: this.zip_postalCode,
					country: this.country,
					region:
						this.country == "Canada"
							? this.store.canadaProvinceOptions[this.state_province]
							: this.country == "United States"
							? this.store.unitedStatesStateOptions[this.state_province]
							: this.state_provice_TF,
					phone: this.phone,
					email: this.email.trim(),
					comments: this.comments.trim(),
					restrictedAccessName: this.restrictedAccessName.trim(),
				});

				this.$emit("nextStep");
			} else {
				// Scroll to first error
				this.$nextTick(() => {
					const el = this.$el.querySelector(
						".v-messages.error--text:first-of-type"
					);
					this.$vuetify.goTo(el, { offset: 125 });

					return;
				});
			}
		},
		previousStep() {
			this.$emit("previousStep");
		},
		isLetter(e) {
			let char = String.fromCharCode(e.keyCode); // Get the character
			if (/^[A-Za-z- ]+$/.test(char)) return true; // Match with regex
			else e.preventDefault(); // If not match, don't add to input text
		},
	},
};
</script>

<style lang="scss" scoped>
.subheader-text {
	font-size: 0.8rem !important;
	height: 30px;
}

// Icon
div ::v-deep .theme--light.v-icon {
	color: $primary !important;
}

// Override RR website styling for textfields
div ::v-deep .v-text-field input {
	border: none !important;
	background: none !important;
	font-size: 16px;
}
</style>
