<template>
	<v-stepper-items>
		<v-stepper-content class="py-0 px-0" step="5">
			<ConfirmationForm
				v-if="store.s1FormData.tribute"
				@formSubmitted="formSubmitted"
				@previousStep="previousStep"
			/>

			<EndForm v-else />
		</v-stepper-content>
	</v-stepper-items>
</template>

<script>
import ConfirmationForm from "../Forms/ConfirmationForm.vue";
import EndForm from "../Forms/EndForm.vue";

import { useFormDataStore } from "../../store/formData.js";

export default {
	name: "S5Form",
	data() {
		return {};
	},
	setup() {
		const store = useFormDataStore();

		return {
			store,
		};
	},
	components: {
		ConfirmationForm,
		EndForm,
	},
	methods: {
		previousStep() {
			this.$emit("previousStepFromFive");
		},
		formSubmitted() {
			this.$emit("formSubmitted");
		},
	},
};
</script>

<style lang="scss" scoped></style>
