import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import CanadaFlag from "../icons/CanadaFlag.vue";
import UnitedStatesFlag from "../icons/UnitedStatesFlag.vue";
import CardVisa from "../icons/CardVisa.vue";
import CardMastercard from "../icons/CardMastercard.vue";
import CardAmericanExpress from "../icons/CardAmericanExpress.vue";
import CardDiscover from "../icons/CardDiscover.vue";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#a71930",
				secondary: "#333333",
				error: "#a71930",
			},
		},
	},
	icons: {
		iconfont: "mdiSvg",
		values: {
			canadaFlag: {
				component: CanadaFlag,
			},
			unitedStatesFlag: {
				component: UnitedStatesFlag,
			},
			cardVisa: {
				component: CardVisa,
			},
			cardMastercard: {
				component: CardMastercard,
			},
			cardAmericanExpress: {
				component: CardAmericanExpress,
			},
			cardDiscover: {
				component: CardDiscover,
			},
		},
	},
});
